/*******************************
        Site Overrides
*******************************/

/* Make the toasts look more line car's native ones by making them more opaque and
having inverted text */
.ui.success.message .header,
.ui.warning.message .header {
  color: @invertedSelectedTextColor;
}

.ui.success.message {
  background-color: @oliveTextColor;
  color: @invertedTextColor;
}

.ui.success.message,
.ui.attached.success.message {
  box-shadow: @oliveBoxShadow;
}

.ui.warning.message {
  background-color: @orangeTextColor;
  color: @invertedTextColor;
}

.ui.warning.message,
.ui.attached.success.message {
  box-shadow: @orangeBoxShadow;
}

/* No idea why Semantic would set width to 100% when there's an icon in the message,
making the 'compact' style not possible */
.ui.icon.message {
  width: unset;
}