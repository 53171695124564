.ui.message.ConnectionPopup {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10;
  box-shadow: 3px 7px 10px 0 rgba(34,36,38,0.52);
}

.ui.message.ConnectionPopup > .icons {
  margin-right: 10px;
}

.ui.message.ConnectionPopup > .icons:last-of-type {
  margin-right: 20px;
}