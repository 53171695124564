/*******************************
         Site Overrides
*******************************/

i.icons .icon {
  left: 48%;
}

i.icon {
  width: 1em;
  margin: 8px;
}