/* Make the visual representation of the scrollbar go away */
body ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* Set all font families to the native Tesla's 'Gotham' fonts */
*, button, input, optgroup, select, textarea {
  font-family: 'Gotham Medium', sans-serif;
}

/* Disable the ugly gray highlight when you tap anything in the UI */
* {
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: black;
}